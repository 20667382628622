<template>
  <div ref="sidebarRef" class="asf-sidebar">
    <AsfOverlay :visible="isOverlayVisible" />
    <transition :name="transition">
      <aside
        v-if="visible"
        ref="content"
        v-focus-trap="useFocusTrap"
        v-click-outside="closeEventHandler"
        v-e2e="`sidebar-${position}`"
        class="asf-sidebar__aside"
        :class="[`is-${position}`]"
        role="complementary"
        @keydown.esc="closeEventHandler"
      >
        <AsfSidebarHead
          :title="barTitle || title"
          :is-back-button="showBackButton || isBackButton"
          :is-close-button="isCloseButton"
          @click:back="backEventHandler"
          @click:close="closeEventHandler"
        >
          <slot name="content-bar" :title="barTitle || title" :close-event-handler="closeEventHandler" />
        </AsfSidebarHead>
        <div class="asf-sidebar__content is-scrollable" v-e2e="'content-sliding-panel'">
          <slot :handle-level-change="handleLevelChange" :close-event-handler="closeEventHandler" />
        </div>
        <div v-if="$slots['content-bottom-section']" class="asf-sidebar__bottom-section">
          <!--@slot Use this slot to place additional content under the scrollable section. -->
          <slot name="content-bottom-section" />
        </div>
      </aside>
    </transition>
  </div>
</template>
<script lang="ts" setup>
import type { AsfSidebarProps, AsfSidebarSlidingPanelState, AsfTransitions } from '@ui/types'
const props = withDefaults(defineProps<AsfSidebarProps>(), {
  position: 'left',
  title: '',
  overlay: true,
  isCloseButton: true,
  useFocusTrap: true
})
const emit = defineEmits(['sidebar:close'])

const instance = useCurrentInstance()
const barTitle: Ref<AsfSidebarProps['title']> = ref('')
const showBackButton = ref(false)
const sidebarRef: Ref<HTMLElement | null> = ref(null)

const backEventHandler = () => EventBus.emit('sidebar:back')
const closeEventHandler = () => {
  barTitle.value = ''
  showBackButton.value = false
  emit('sidebar:close')
}

const isOverlayVisible = computed(() => props.visible && props.overlay)
const transition = computed<AsfTransitions>(() => {
  if (props.position === 'left') {
    return 'asf-slide-left'
  }

  return 'asf-slide-right'
})

const handleLevelChange = (payload: AsfSidebarSlidingPanelState) => {
  barTitle.value = payload.panels[payload.level - 1]?.label || props.title
  showBackButton.value = Boolean(payload.level)

  useBodyScrollLock(props, instance, closeEventHandler, true)
}

useBodyScrollLock(props, instance, closeEventHandler)
</script>
<style lang="postcss">
@import '@components/molecules/Sidebar/Sidebar.css';
</style>
