<template>
  <div class="asf-sidebar__head">
    <slot name="content-back-button">
      <AsfButton
        v-if="isBackButton"
        :aria-label="`${$t('global.goBack')}`"
        class="asf-sidebar__head__button asf-sidebar__head__back"
        @click="$emit('click:back')"
      >
        <AsfIcon name="chevron-left" :title="$t('global.goBack')" />
      </AsfButton>
    </slot>
    <div class="asf-sidebar__head__content">
      <slot v-bind="{ title }">
        <AsfHeading v-if="title" tag="h3" appearance="h5">
          {{ title }}
        </AsfHeading>
      </slot>
    </div>
    <slot name="content-close-button">
      <AsfButton
        v-if="isCloseButton"
        v-e2e="'close-button-sliding-panel'"
        :aria-label="`${$t('action.close')}`"
        class="asf-sidebar__head__button asf-sidebar__head__close"
        @click="$emit('click:close')"
      >
        <AsfIcon name="close" :title="$t('global.close')" />
      </AsfButton>
    </slot>
  </div>
</template>
<script lang="ts">
import type { AsfSidebarHeadProps } from '@ui/types'

export default defineComponent({
  name: 'AsfSidebarHead',
  props: {
    isBackButton: { type: Boolean as PropType<AsfSidebarHeadProps['isBackButton']>, default: false },
    isCloseButton: { type: Boolean as PropType<AsfSidebarHeadProps['isCloseButton']>, default: true },
    title: { type: String as PropType<AsfSidebarHeadProps['title']>, default: '' }
  }
})
</script>
